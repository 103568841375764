.group-color {
  min-width: 10px;
  min-height: 10px;
  border-radius: 100%;
}

.group-color-title {
  min-width: 12px;
  min-height: 12px;
  border-radius: 100%;
}

.group-color-squared {
  min-width: 10px;
  min-height: 10px;
  border-radius: 2px;
}

.group-color-squared-title {
  min-width: 12px;
  min-height: 12px;
  border-radius: 2px;
}

.group-color-small {
  min-width: 6px;
  min-height: 6px;
  border-radius: 100%;
}

.group-color-small-squared {
  min-width: 6px;
  min-height: 6px;
  border-radius: 2px;
}
