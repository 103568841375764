@use 'var' as *;
@use 'typography' as *;

.fc-table {
  width: 100%;
  border-top: 1px solid $grey-100;

  &.multiple-select {
    th.mat-mdc-header-cell:first-child {
      width: 40px;
      text-align: center;
    }

    th.mat-mdc-header-cell:nth-child(2) {
      text-align: left;
    }
  }

  mat-header-cell,
  th.mat-mdc-header-cell {
    height: 72px;
    vertical-align: middle;
    color: $grey-400;
    padding: 0 24px;
    @extend .subtitle !optional;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  mat-cell,
  td.mat-mdc-cell {
    height: 72px;
    vertical-align: middle;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 24px;
    @extend .body !optional;

    &:first-child {
      text-align: left;
    }

    &:last-child {
      text-align: right;
    }
  }

  tr {
    &:hover td.mat-mdc-cell {
      background: $secondary-50;
    }
  }
}

.mat-mdc-header-cell {
  color: $grey-500;
}
