// Screen sizes
$md-size: 1200px;
$sm-size: 900px;

:root {
  --md-size: #{$md-size};
  --sm-size: #{$sm-size};
}

@mixin small-screen {
  @media (width <= $sm-size) {
    @content;
  }
}

$full-mobile-height: 100dvh;

// new breakpoints (for migrations);
$sm: 640px; // mobile
$md: 768px; // tablet
$lg: 1024px; // laptop
$xl: 1280px; // desktop
