@use 'var' as *;
@use 'theme' as *;
@use 'reports' as *;
@use 'reports-print' as *;
@use 'animatins' as *;
@use 'components' as *;
@use 'typography' as *;
@use 'map-markers' as *;
@use 'utils' as *;
@use 'destyle' as *;
@use 'material' as *;

:root {
  --app-height: 100%;
}

html,
body {
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
  font-family: 'Roboto', sans-serif;
  background-color: $primary-950;
  color: $grey-900;
  font-weight: $font-weight-400;
  font-size: $text-size-14;
}

/*
  Custom scrollbar only for desktop
*/
@media (min-width: $sm-size) {
  ::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: $secondary-300;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $secondary-500;
  }
}

button:focus {
  outline: 0;
}

.gm-style iframe + div {
  border: none !important;
}
