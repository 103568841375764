@use 'var' as *;
@use 'typography' as *;

// override material colors in root
:root {
  --mat-form-field-container-text-size: 14px;
  --mdc-outlined-text-field-input-text-color: var(--grey-900);
  --mdc-outlined-text-field-outline-color: var(--secondary-200);
  --mdc-outlined-text-field-hover-outline-color: var(--grey-300);
  --mdc-outlined-text-field-label-text-color: var(--grey-300);
}

mat-form-field.white-background .mdc-text-field--outlined {
  background: white;
}

mat-form-field {
  width: 100%;
}

mat-form-field .mat-mdc-form-field-icon-suffix {
  fc-icon {
    padding: 0 8px;
  }

  button fc-icon {
    padding: 0;
  }
}

// custom focused input for dropdown (select-dropdown)
.fc-text-field--focused
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $primary-600;
  }
}

.mat-mdc-radio-button {
  --mat-checkbox-label-text-color: $gray-500;
}

.mat-mdc-select {
  --mat-select-enabled-trigger-text-color: $gray-900;
}
.mat-mdc-option {
  --mat-option-label-text-color: $gray-900;
}

.mdc-floating-label--float-above {
  --mdc-outlined-text-field-label-text-color: $grey-300;
}
.mat-mdc-form-field-hint {
  color: $grey-200;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
  --mdc-outlined-text-field-label-text-color: $grey-300;
}

.mdc-text-field--outlined .mdc-floating-label {
  --mdc-outlined-text-field-label-text-size: 14px;
}

.mdc-list-item__primary-text {
  --mdc-list-list-item-label-text-color: $grey-900;
}
// input has no margin because of subscriptSizing are dynamic
form mat-form-field {
  margin-bottom: 16px;
}

textarea {
  resize: vertical;
}
