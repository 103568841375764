@use 'variables/typography' as *;

// todo refactor all of the values here
.numbers {
  font-size: $text-size-32;
  font-weight: $font-weight-500;
  line-height: 38px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-weight: $font-weight-500;
}

.button_tab,
.body,
.subtitle,
.label {
  font-weight: $font-weight-400;
}

h1,
.h1 {
  font-size: $text-size-24;
  line-height: $line-height-28;
}

h2,
.h2 {
  font-size: $text-size-20;
  line-height: $line-height-24;
}

h3,
.h3 {
  font-size: $text-size-16;
  line-height: $line-height-20;
}

h4,
.h4 {
  font-size: $text-size-14;
  line-height: $line-height-16;
}

.button_tab {
  font-size: $text-size-14;
  line-height: $line-height-16;
  letter-spacing: 0.5px;
}

.button_tab-2 {
  font-size: $text-size-12;
  line-height: $line-height-14;
  letter-spacing: 0.5px;
}

.body {
  font-size: $text-size-14;
  line-height: $line-height-20;
}

.label-1 {
  font-size: 11px;
}

// rename on design system (right now body/text2)
.body-2 {
  font-size: $text-size-12;
  line-height: $line-height-20;
}

.subtitle,
h5,
.h5 {
  font-size: $text-size-12;
}

.label {
  font-size: $text-size-11;
}

// todo new typography classes (remove old ones on top)
.headline-lg {
  font-size: 32px;
}
.headline-md {
  font-size: 24px;
}
.headline-sm {
  font-size: 20px;
}
.text-lg {
  font-size: 16px;
}
.text-md {
  font-size: 14px;
}
.text-sm {
  font-size: 12px;
}

.weight-500 {
  font-weight: 500;
}
