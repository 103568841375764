@use 'var' as *;

/* form errors */
// todo delete after form error component refactoring
.invalid-feedback {
  font-weight: 300;

  ul {
    padding: 0;

    li {
      list-style: none;
    }
  }
}

.form-errors {
  padding: 0 10px;

  .invalid-feedback {
    margin-top: 0;
    color: $error-500;
  }
}

.has-warning {
  tspan:first-child {
    display: none;
  }
}

.no-warning {
  tspan:last-child {
    display: none;
  }
}

.status-badge {
  box-shadow: $box-shadow-md;
  position: absolute;
  top: -10px;
  left: -10px;
  background: white;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}
