@use 'var' as *;

// reassign material variables for tab styles
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mat-tab-header-inactive-label-text-color: var(--grey-400);
}

.mat-mdc-tab-header {
  border-bottom: 1px solid $grey-100;
  text-transform: uppercase;
}
