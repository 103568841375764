@use 'var' as *;

.mat-mdc-paginator {
  box-shadow: $box-shadow-md;
}

div.mat-mdc-paginator-page-size-label {
  @media (max-width: $sm-size) {
    display: none;
  }
}

div.mat-mdc-paginator-container {
  padding: 16px 28px;
  @media (max-width: $sm-size) {
    padding: initial;
    flex-wrap: nowrap;
  }
}

div.mat-mdc-paginator-range-label {
  @media (max-width: $sm-size) {
    margin: 0 10px;
  }
}

mat-paginator {
  border-top: 1px solid $grey-100;
}

.mat-mdc-paginator {
  @media (max-width: $sm-size) {
    position: sticky;
    left: 0;
    margin-bottom: 10px;
  }
}
