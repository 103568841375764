@use 'var' as *;

:root {
  --mat-paginator-container-text-color: var(--grey-900);
  --mat-table-row-item-label-text-color: var(--grey-900);
  --mat-table-header-headline-size: 12px;
}

.table {
  box-shadow: $box-shadow-md;
  width: 100%;

  &.no-shadow {
    box-shadow: none;
  }

  .time {
    font-size: 14px;
  }

  .date,
  .seconds {
    color: $grey-300;
    font-size: 11px;
  }

  .location-type {
    display: flex;
  }

  &__th {
    padding: 15px 0;
    vertical-align: middle;
    color: $grey-400;
    @media (max-width: $sm-size) {
      white-space: nowrap;
    }
  }

  &__tr {
    height: auto;
  }

  @media (max-width: $sm-size) {
    width: 100%;
    border: 1px solid $grey-100;
  }

  &.table-expanded {
    td:first-child,
    td:first-child + td,
    th:first-child + th,
    th:first-child {
      text-align: left;

      .mat-sort-header-container {
        flex-direction: row !important;
      }
    }
  }
}
.table-td {
  padding: 15px 0;
  color: $grey-900;
  text-align: left;
  border-bottom: 1px solid $grey-100;
  vertical-align: middle;

  &:first-child {
    @media (max-width: $sm-size) {
      color: $grey-900;
    }
  }

  @media (max-width: $sm-size) {
    white-space: nowrap;
    padding: 10px 25px;
  }
}

.table-td-with-icon {
  position: relative;
}

td.mdc-data-table__cell {
  padding: 16px 28px;
}

th.mdc-data-table__header-cell {
  padding: 0 28px;
}
fc-ifta-group-table {
  .mdc-data-table__row.detail-row {
    height: 0;
    .mdc-data-table__cell {
      padding: 0;
    }
  }
  td.mat-column-expandedDetail li {
    padding: 16px 28px;
  }
  tr.element-row:not(.detail-row):hover {
    background: rgba($secondary-50, 0.3);
  }

  tr.element-row:not(.detail-row):active {
    background: rgba($secondary-50, 0.3);
  }

  .element-row td {
    cursor: pointer;
    border-bottom-width: 0;
  }

  td.mat-mdc-cell {
    border-color: $grey-100;
  }
  .collapse-icon {
    color: $secondary-400;
    margin-left: 16px;

    @media print {
      display: none;
    }
  }
  .states-list {
    width: 100%;

    li {
      padding: 15px;
    }
  }
}

@media (max-width: $sm-size) {
  .report-table {
    overflow: auto;
  }

  thead:not(.mat-calendar-table-header) th {
    padding: 0 10px !important;
  }
  td:not(.mat-calendar-body-cell-container, .mat-calendar-body-label) {
    padding: 0 10px !important;
  }
}

fc-vehicle-work-hours-table {
  .table {
    width: 100%;

    &__icon {
      font-size: 24px;
      color: $warning-400;
      position: absolute;
      right: 85px;
      top: 10px;
    }

    &__td {
      text-align: start;
      padding: 15px 0;
      border-bottom: 1px solid $grey-100;

      &__cdk-column-day &_with-icon {
        position: relative;
      }

      @media (max-width: $sm-size) {
        white-space: nowrap;
        padding: 10px 25px;
      }
    }

    &__th {
      padding: 15px 0;
      @media (max-width: $sm-size) {
        padding: 15px 25px;
        white-space: nowrap;
      }
    }

    &__tr {
      height: auto;
    }
  }
  .table {
    .table__th {
      &.states {
        width: 100px;
      }

      &.hoursWorked {
        width: 150px;
      }
    }
  }

  td.expanded-cell {
    padding: 0 !important;
  }

  .inner-table {
    border: none;
    box-shadow: none;

    .table-td {
      border-bottom: 0;

      &:last-of-type {
        padding-right: 24px;
      }
    }

    .table__th {
      border-bottom: 0;
      font-size: inherit;
      padding: 0;
      height: 0;
      overflow: hidden;
    }

    tbody {
      transform: translateY(-10px);
    }

    thead {
      visibility: hidden;
    }
  }
  .table-mobile {
    display: none;
    width: 100%;
    background-color: $white;
    box-shadow: $box-shadow-md;
    border-radius: 3px;
    padding: 15px;
    margin-top: 10px;

    &__icon {
      color: $secondary-400;
    }
  }

  .mat-mdc-table tr.expanded-detail-row {
    height: 0;
  }

  tr.expanded-element-row:not(.element-expanded-row):hover {
    background: rgba($secondary-100, 0.7);
  }

  tr.expanded-element-row:not(.element-expanded-row):active {
    background: $secondary-50;
  }

  .expanded-element-row td {
    border-bottom-width: 0;
  }

  .expanded-element-detail {
    overflow: hidden;
    display: flex;
  }

  td.table-td {
    border-bottom: none !important;
  }

  .collapse-icon {
    color: $secondary-400;
    margin-left: 16px;

    @media print {
      display: none;
    }
  }

  .element-expanded-row .collapse-icon {
    transform: rotate(180deg);
  }

  .collapse-icon.active {
    transform: rotate(180deg);
  }

  tr.mat-mdc-table .mdc-data-table__row {
    height: 0;
  }
}
fc-dvr-settings-table {
  .dvr-settings-table-container {
    overflow: auto;
  }

  tr {
    transition: 0.3s;
  }

  th.vehicle-column,
  td.th.vehicle-column {
    min-width: 200px;
  }
  .dvr-settings-table {
    border-top: none;

    tr:last-of-type td {
      border-bottom: none;
    }

    th.resolution-th,
    td.resolution-td {
      padding-right: 100px;
      position: relative;

      @media (max-width: $sm-size) {
        padding-right: 24px;
        .vehicle-edit {
          display: none;
        }
      }
    }

    @media (max-width: $sm-size) {
      border-top: none;
    }
  }

  tr:hover td.mat-mdc-cell .vehicle-edit {
    display: block;
    opacity: 1;

    @media (max-width: $sm-size) {
      display: none;
    }
  }
}
