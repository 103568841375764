@use 'var' as *;

// for sorting tables
.report .mat-mdc-table {
  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      &:first-child {
        .mat-sort-header-container {
          flex-direction: row;
        }
      }
    }
  }
}

.not-found {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
