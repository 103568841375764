@use 'var' as *;

@mixin marker-style($state, $stroke-color, $fill-color) {
  .destination-icon.#{$state} circle {
    fill: $fill-color;
  }

  .marker-container.#{$state} {
    outline: none;

    &.active::before {
      content: '';
      position: absolute;
      width: 70px;
      height: 70px;
      border-radius: 100%;
      background: rgba($fill-color, 0.2);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }

    .marker .marker-path {
      fill: $fill-color;
      stroke: $stroke-color;
    }

    .marker .stroke-style {
      stroke: $stroke-color;
    }

    .marker .fill-style {
      fill: $fill-color;
    }

    .marker .fill-text {
      fill: $stroke-color;
    }

    .line {
      background-color: $stroke-color;
    }

    .label {
      border-color: $stroke-color;
    }

    // only for arrow markers
    .marker .inner-circle {
      stroke: $stroke-color;
    }
  }
}

$states: (
  'stopped' $secondary-600 $secondary-300,
  'idling' $warning-600 $warning-400,
  'traveling' $success-700 $success-500,
  'marker-preview' $success-700 $success-500,
  'towed' $keppel-700 $keppel-500,
  'user' $primary-800 $primary-600,
  'non-tracker' $primary-600 $primary-800,
  'error' $error-500 $error-800
);

@each $state, $border, $color in $states {
  @include marker-style($state, $border, $color);
}

// transform small marker due it's size
svg.marker.compact-type {
  transform: translate(0px, 8px);
}

.marker-container {
  transform-origin: bottom center;
  position: relative;

  &.traveling,
  &.towed {
    .arrow-tip {
      display: block;
    }
  }

  &.non-tracker {
    .speed-text {
      display: none;
    }
  }

  .arrow-tip {
    display: none;
  }

  .line {
    position: absolute;
    bottom: 50%;
    left: 50%;
    height: 1px;
    width: 100px;
    transform-origin: left bottom;
    transform: rotate(0deg);
    z-index: -1;
  }

  .label {
    position: absolute;
    display: flex;
    gap: 5px;
    align-items: center;
    white-space: nowrap;
    padding: 4px;
    border-radius: 4px;
    border: 1px solid;
    background: white;
    letter-spacing: 0.05px;
    text-transform: uppercase;
    transform: translate(0px, 5px);

    span {
      z-index: 1;
      color: $secondary-500;
      font-size: 11px;
      font-weight: 500;
      display: flex;
    }

    // add circle after every item
    span:not(:last-child)::after {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background: $secondary-500;
      align-self: center;
      margin-left: 5px;
    }

    &.black {
      background: black;
      border-color: black;

      span {
        color: white;
      }

      .group-item {
        box-shadow: none;
        color: white;
      }
    }
  }

  .circle-orbit {
    left: 50%;
    position: absolute;
    top: 50%;
    width: 2em;
    height: 2em;
    margin-top: -1em;
    margin-left: -1em;
  }
}

.location-marker {
  .location-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -18px);
    font-size: 18px;
  }
}

fc-cluster-tooltip-element.show {
  display: block;
}

.marker-container {
  .group-item {
    box-shadow: 0 0 0 3px white;
  }
}

fc-cluster-tooltip-element .marker-container {
  transform: translate(0, 0);
}

fc-cluster-tooltip-element {
  .marker-type {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;

    .marker {
      scale: 0.6;
      transform: rotate(90deg);

      &.arrow-type,
      &.circle-type,
      &.circle-non-tracker-type,
      &.arrow-non-tracker-type {
        transform: rotate(90deg) translate(3px, 10px);
      }
    }

    .type-image {
      display: none;
    }
  }
}

.speed-text {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  fill: $secondary-500;
  font-size: 12px;
}
