@use 'var' as *;

.printed-head {
  display: none;
  height: 100px;
  background-color: transparent;

  th {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      display: block;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: 0;
      background-color: $white;
    }
  }
}

.print-title {
  display: none;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 500;
  color: $white;
  position: fixed;
  top: 24px;
  left: 24px;
  right: 24px;
  z-index: 10;

  h1 {
    font-size: 14px;
    line-height: 16px;
  }

  .dates {
    color: $white;
    font-size: 10px;
    line-height: 13px;
    display: flex;
    align-items: center;
  }

  fc-icon {
    display: none;
  }
}

.print-table {
  display: none;
}

@media print {
  thead {
    display: table-header-group;
  }
  .non-print-table {
    display: none;
  }
  .print-table {
    display: table;
  }
  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: auto;
    page-break-after: auto;
  }

  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }
  body,
  html {
    position: static !important;
    color: black !important;
    background-color: $white !important;
  }

  .app-container {
    display: block !important;

    .content {
      overflow: initial !important;
    }
  }

  fc-navigation {
    display: none !important;
  }
  div.reports-header {
    display: none !important;
  }

  .reports-container {
    padding: 10px !important;
    background-color: white !important;

    // every container for print should be static
    position: static !important;
    // and should have default overflow
    overflow: initial !important;

    &__content {
      position: unset !important;
    }

    .nav {
      display: none;
    }

    .date-range-picker {
      display: none;
    }
  }
  .reports-container-page {
    overflow: initial !important;
  }

  .mobile-header {
    display: none !important;
  }
  .vehicles {
    box-shadow: none !important;
    display: none;

    fc-icon {
      display: none;
    }
  }
  .print-title {
    display: flex !important;
  }

  .report-container {
    display: block !important;
    flex-direction: column;
    height: unset !important;
    width: 100% !important;

    .toggle-items {
      display: none !important;
    }
  }
  .report-container-content {
    height: unset !important;
    position: relative !important;
    background: unset !important;
    padding: 0 !important;
    overflow: initial !important;

    router-outlet {
      height: 0;
    }
  }
  .duration-container {
    flex-wrap: wrap;
  }
  .cards {
    margin: unset !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  .card {
    box-shadow: unset !important;
    background-color: unset !important;
    margin-bottom: unset !important;
    padding: unset !important;
    width: auto !important;

    &::before {
      display: none !important;
    }

    &-content {
      display: none !important;
    }

    &-title {
      margin-bottom: 4px !important;

      &_bold {
        margin: unset !important;
      }
    }

    &-icon {
      display: none !important;
    }
  }
  div.report-header {
    display: none !important;
  }
  .geomap {
    height: 180px !important;
    width: 300px !important;

    &-chart {
      height: 180px !important;
      width: 300px !important;
    }
  }
  .millage {
    margin-bottom: 16px;
    box-shadow: unset !important;

    &-header {
      padding: unset !important;
      line-height: 16px !important;
      font-size: 13px !important;
    }

    &-states {
      margin: unset !important;
      border: unset !important;
    }
  }
  table.table {
    box-shadow: unset !important;
  }
  table {
    td,
    th {
      white-space: normal !important;
      padding-left: 10px !important;
    }
  }
  .vehicle-work-hours .show-all {
    display: none;
  }
  .vehicle-work {
    margin-top: 30px !important;
  }
  .expander {
    position: relative;
    margin-bottom: -40px !important;
    z-index: 11;
  }
  .vehicle-usage__content {
    & > .cards {
      margin-top: 30px !important;
    }

    .popular {
      position: relative;
      z-index: 11;
      margin-bottom: -40px !important;
    }
  }
  .vehicle-odometer__content {
    & > .cards {
      margin-top: 30px !important;
      position: relative;
      z-index: 11;
      margin-bottom: -50px !important;
    }
  }
  .expanded-element-detail {
    height: auto !important;
    min-height: auto !important;
  }
  .inner-table {
    border: none !important;
  }
  .odometer-header {
    display: none !important;
  }
  .mat-mdc-paginator {
    display: none !important;
  }
  .states .address {
    white-space: normal;
    word-break: normal;
  }
  .states .state-list {
    flex-direction: row !important;
    justify-content: space-between;
  }
  .states .cards {
    margin-top: 30px !important;
  }
  .millage,
  .millage-content {
    width: 100% !important;
  }
  .millage-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .state-chart {
    overflow: hidden !important;
    margin-bottom: -40px !important;
    position: relative;
    z-index: 11;
  }
  .printed-head {
    display: table-row;
  }
  .chart {
    margin-bottom: -40px !important;
    margin-top: 30px;
    position: relative;
    z-index: 11;
  }
  .ifta__content {
    margin-top: 30px;

    .bar-chart {
      padding-top: unset !important;
    }

    .show-all {
      display: none;
    }

    .statistic-container > .section-title {
      position: relative;
      z-index: 11;
      margin-bottom: -40px !important;
    }
  }
  svg.legend {
    display: none;
  }

  .duration-per-vehicle-chart,
  .location-chart {
    display: none !important;
  }
  .states-count,
  .state-name {
    text-align: center !important;
  }
}
