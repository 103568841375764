@use 'var' as *;

:root {
  --mdc-chip-elevated-container-color: var(--secondary-100);
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: var(--secondary-100);
}
.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  .mdc-evolution-chip__text-label {
  --mdc-chip-label-text-color: var(--grey-400);
}
