@use 'var' as *;

:root {
  --mat-standard-button-toggle-selected-state-text-color: var(--grey-900);
  --mat-standard-button-toggle-selected-state-background-color: var(
    --secondary-100
  );
  --mat-standard-button-toggle-text-color: var(--grey-400);
  --mat-standard-button-toggle-height: 32px;
  --mat-standard-button-toggle-label-text-size: 14px;
  --mat-standard-button-toggle-divider-color: var(--secondary-200);
}

.mat-button-toggle-group {
  width: 100%;
}

.mat-button-toggle {
  flex: 1;
}

.filter-toggles .mat-button-toggle-button {
  height: 40px;
}

.statistics-toggle {
  @extend .gap-20;
  display: flex;
  align-items: center;
  mat-radio-button {
    .mdc-form-field {
      @extend .gap-8;

      .mdc-radio {
        padding: 0;
      }

      .mdc-label {
        font-size: 12px;
        color: $grey-500;
      }
    }
  }
  @media (max-width: $sm-size) {
    gap: 8px;
  }
}
