@use 'var' as *;

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
  margin-bottom: 30px;
  gap: 30px;
  padding: 2px;

  @media (max-width: $sm-size) {
    display: flex;
    overflow: auto;
    gap: 10px;
    margin: 10px 0;
  }
}

.card {
  box-shadow: $box-shadow-md;
  border-radius: 4px;
  background-color: white;
}
