@use 'var' as *;

:root {
  button.white-button {
    --mdc-fab-small-container-color: #fff;
  }
}

button.warn {
  --mdc-text-button-label-text-color: var(--warning-500);
}

button.error {
  --mdc-text-button-label-text-color: var(--error-500);
}

button.accent {
  --mdc-text-button-label-text-color: var(--primary-600);
}

.mat-mdc-button {
  --mdc-text-button-label-text-color: var(--grey-900);
}
