@use 'variables/colors' as *;

.icon-default {
  color: $secondary-400;
}

.icon-grey {
  color: $secondary-300;
}

.icon-action {
  color: $primary-500;
}

.icon-disable {
  color: $secondary-200;
}

.icon-error {
  color: $error-500;
}

.icon-warning {
  color: $warning-400;
}

.icon-success {
  color: $success-500;
}

.icon-light {
  color: $white;
}

.icon-hover-background {
  color: $secondary-50;
}

.icon-hover-background-bar {
  color: $primary-900;
}

.color-main {
  color: $primary-600;
}

.color-icon {
  color: $secondary-400;
}

.fc-icon-color {
  color: $secondary-400;
}

.color-secondary {
  color: $grey-400;
}

.color-label {
  color: $grey-300;
}

.color-white {
  color: $white;
}

.color-red {
  color: $error-500;
}

.state-block {
  &.stopped {
    background: $secondary-300;
  }

  &.idling {
    background: $warning-400;
  }

  &.traveling {
    background: $success-500;
  }

  &.towed {
    background: $keppel-500;
  }
}

.text-title {
  color: $grey-900;
}
