$spacing-num: (
  2: 2px,
  4: 4px,
  6: 6px,
  8: 8px,
  12: 12px,
  16: 16px,
  20: 20px,
  24: 24px,
  32: 32px,
  40: 40px,
  48: 48px,
);

@mixin spacing($space-map) {
  @each $name, $space in $space-map {
    .m-b-#{$name} {
      margin-bottom: $space;
    }

    .m-t-#{$name} {
      margin-top: $space;
    }

    .m-l-#{$name} {
      margin-left: $space;
    }

    .m-r-#{$name} {
      margin-right: $space;
    }

    .m-x-#{$name} {
      margin-left: $space;
      margin-right: $space;
    }

    .m-y-#{$name} {
      margin-top: $space;
      margin-bottom: $space;
    }

    .m-#{$name} {
      margin: $space;
    }

    .p-b-#{$name} {
      padding-bottom: $space;
    }

    .p-t-#{$name} {
      padding-top: $space;
    }

    .p-l-#{$name} {
      padding-left: $space;
    }

    .p-r-#{$name} {
      padding-right: $space;
    }

    .p-x-#{$name} {
      padding-left: $space;
      padding-right: $space;
    }

    .p-y-#{$name} {
      padding-top: $space;
      padding-bottom: $space;
    }

    .p-#{$name} {
      padding: $space;
    }

    .gap-#{$name} {
      gap: $space;
    }
  }
}

@include spacing($spacing-num);

$material-margin-bottom: 22px;
$material-field-height: 40px;
