@use 'var' as *;

.view-switcher {
  border: 1.5px solid rgba($grey-100, 0.5);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  margin-right: 10px;
  gap: 10px;

  .view-container {
    width: 24px;
    height: 20px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon-normal {
      font-size: 16px;
      height: 16px;
      width: 16px;
    }
  }

  .view-icon {
    color: $white;
  }

  .active {
    background-color: white;

    .view-icon {
      color: $grey-900;
    }
  }
}

.video-controls {
  right: 0;
  left: 0;
  padding: 10px;
  position: absolute;
  bottom: 0;
  transition: all 0.2s ease;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.5)
  );
  display: flex;
  justify-content: space-between;
  color: $white;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .disabled fc-icon {
    opacity: 0.5;
  }

  @media (max-width: $sm-size) {
    .play-controller-button {
      display: none;
    }
  }

  @media (max-width: $sm-size) and (orientation: landscape) {
    align-items: flex-start !important;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .play-button {
    display: flex;
    margin-right: 10px;

    @media (max-width: $sm-size) {
      button:first-of-type {
        display: none;
      }
    }
  }
}

.mobile-overlay {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;

  @media (max-width: $sm-size) {
    display: flex;
    z-index: 12;
  }
}
