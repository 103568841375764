// root colors
$primary-50: #f8fdff;
$primary-100: #e0f4fe;
$primary-200: #b9ebfe;
$primary-300: #7cdbfd;
$primary-400: #36cbfa;
$primary-500: #0cb5eb;
$primary-600: #009cd7;
$primary-700: #0174a3;
$primary-800: #066186;
$primary-900: #153e53;
$primary-950: #113345;

$secondary-50: #f9fafb;
$secondary-100: #eff2f5;
$secondary-200: #c8d4d9;
$secondary-300: #b0bec5;
$secondary-400: #78909c;
$secondary-500: #546e7a;
$secondary-600: #4c616e;
$secondary-700: #42525c;
$secondary-800: #3c474e;
$secondary-900: #353d44;

$grey-50: #f6f6f6;
$grey-100: #e7e7e7;
$grey-200: #d8d8d8;
$grey-300: #b2b2b2;
$grey-400: #8b8b8b;
$grey-500: #6d6d6d;
$grey-600: #5d5d5d;
$grey-700: #4f4f4f;
$grey-800: #454545;
$grey-900: #3e3e3e;

$error-50: #fef3f2;
$error-100: #ffe3e1;
$error-200: #ffccc8;
$error-300: #ffa8a2;
$error-400: #fc776d;
$error-500: #f44336;
$error-600: #e22d20;
$error-700: #be2217;
$error-800: #9d2017;
$error-900: #82211a;

$success-50: #edfcf5;
$success-100: #d3f8e4;
$success-200: #aaf0ce;
$success-300: #72e3b3;
$success-400: #3acd93;
$success-500: #19c98a;
$success-600: #0a9163;
$success-700: #087452;
$success-800: #095c43;
$success-900: #094b38;

$warning-50: #fffbeb;
$warning-100: #fff4c6;
$warning-200: #ffe788;
$warning-300: #ffd95c;
$warning-400: #ffc120;
$warning-500: #f99f07;
$warning-600: #dd7702;
$warning-700: #b75306;
$warning-800: #943f0c;
$warning-900: #7a350d;

$keppel-100: #d8f5f0;
$keppel-200: #a8ebe1;
$keppel-300: #7edcd0;
$keppel-500: #25aaa2;
$keppel-600: #1b8682;
$keppel-700: #196c69;

$overlay: rgba(0, 0, 0, 0.5);
$white: #ffffff;
$black: #000000;

$colorMap: (
  primary-50: $primary-50,
  primary-100: $primary-100,
  primary-200: $primary-200,
  primary-300: $primary-300,
  primary-400: $primary-400,
  primary-500: $primary-500,
  primary-600: $primary-600,
  primary-700: $primary-700,
  primary-800: $primary-800,
  primary-900: $primary-900,
  primary-950: $primary-950,
  secondary-50: $secondary-50,
  secondary-100: $secondary-100,
  secondary-200: $secondary-200,
  secondary-300: $secondary-300,
  secondary-400: $secondary-400,
  secondary-500: $secondary-500,
  secondary-600: $secondary-600,
  secondary-700: $secondary-700,
  secondary-800: $secondary-800,
  secondary-900: $secondary-900,
  grey-50: $grey-50,
  grey-100: $grey-100,
  grey-200: $grey-200,
  grey-300: $grey-300,
  grey-400: $grey-400,
  grey-500: $grey-500,
  grey-600: $grey-600,
  grey-700: $grey-700,
  grey-800: $grey-800,
  grey-900: $grey-900,
  error-50: $error-50,
  error-100: $error-100,
  error-200: $error-200,
  error-300: $error-300,
  error-400: $error-400,
  error-500: $error-500,
  error-600: $error-600,
  error-700: $error-700,
  error-800: $error-800,
  error-900: $error-900,
  success-50: $success-50,
  success-100: $success-100,
  success-200: $success-200,
  success-300: $success-300,
  success-400: $success-400,
  success-500: $success-500,
  success-600: $success-600,
  success-700: $success-700,
  success-800: $success-800,
  success-900: $success-900,
  warning-50: $warning-50,
  warning-100: $warning-100,
  warning-200: $warning-200,
  warning-300: $warning-300,
  warning-400: $warning-400,
  warning-500: $warning-500,
  warning-600: $warning-600,
  warning-700: $warning-700,
  warning-800: $warning-800,
  warning-900: $warning-900,
  kepple-100: $keppel-100,
  kepple-500: $keppel-500,
  kepple-600: $keppel-600,
  kepple-700: $keppel-700,
  'white': $white,
  'black': $black,
);

@each $name, $color in $colorMap {
  .color-#{$name} {
    color: $color;
  }

  .bg-#{$name} {
    background-color: $color;
  }

  :root {
    --#{$name}: #{$color};
  }
}

.text-body {
  color: $grey-500;
}

.text-secondary {
  color: $secondary-400;
}
