@use 'variables/colors' as *;

.border-light {
  border: 1px solid $grey-100;
}

.border-bottom-light {
  border-bottom: 1px solid $grey-100;
}

.b-b-1 {
  border-bottom: 1px solid $secondary-100;
}
