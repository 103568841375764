:root {
  --mdc-checkbox-unselected-icon-color: var(--secondary-300);
  --mdc-checkbox-unselected-hover-icon-color: var(--secondary-300);
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-unselected-icon-color: var(--secondary-300);
}

.mat-mdc-checkbox {
  --mat-checkbox-label-text-color: var(--grey-500);
}
