@use 'var' as *;

.mat-mdc-dialog-surface {
  border-radius: 12px !important;
}

// todo can be moved to base.scss
.backdrop-color {
  background-color: $overlay;
}

.confirm-dialog .mat-mdc-dialog-component-host {
  min-width: 500px;
  width: 500px;
  max-width: 500px;
  display: block;

  @media (max-width: $sm-size) {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    height: initial;
    position: relative;
  }
}

// Material dialog host styles [required for dialog width]
.full-screen-modal .mat-mdc-dialog-component-host {
  min-width: 836px;
  width: 836px;
  max-width: 836px;
  display: block;

  & > * {
    width: 100%;
  }

  @media (max-width: $sm-size) {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 100dvh;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background: white;
    display: flex !important;
    flex-direction: column;
    overflow: hidden;

    fc-dialog-content {
      flex: 1;
      overflow: auto;
    }
  }
}

fc-dialog-footer,
fc-dialog-custom-footer {
  border-top: 1px solid $secondary-100;

  fc-text-button {
    grid-area: a;
  }

  fc-stroked-button {
    grid-area: b;
  }

  fc-filled-button {
    grid-area: c;
  }

  &:not(.full-footer) {
    justify-content: flex-end;
    gap: 16px;
  }

  &.full-footer {
    display: grid;
    grid-template-areas: 'a . b c';
    justify-content: space-between;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}
