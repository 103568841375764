$box-shadow-xs: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
$box-shadow-sm:
  0px 1px 3px 0px rgba(16, 24, 40, 0.1),
  0px 1px 2px 0px rgba(16, 24, 40, 0.06);
$box-shadow-md:
  0px 4px 8px -2px rgba(16, 24, 40, 0.1),
  0px 2px 4px -2px rgba(16, 24, 40, 0.06);
$box-shadow-lg:
  0px -8px 24.6px -4px rgba(16, 24, 40, 0.05),
  0px 12px 16px -4px rgba(16, 24, 40, 0.08),
  0px 4px 6px -2px rgba(16, 24, 40, 0.03);
$box-shadow-xl:
  0px -1px 11.8px 0px rgba(16, 24, 40, 0.07),
  0px 20px 24px -4px rgba(16, 24, 40, 0.08),
  0px 8px 8px -4px rgba(16, 24, 40, 0.03);
$box-shadow-2xl:
  0px -2px 30.8px 0px rgba(16, 24, 40, 0.08),
  0px 24px 48px -12px rgba(16, 24, 40, 0.18);
$box-shadow-3xl:
  0px -4px 64px 0px rgba(16, 24, 40, 0.1),
  0px 32px 64px -12px rgba(16, 24, 40, 0.14);
