@use 'var' as *;

.error-level {
  display: none;
}

fc-form-field {
  display: block;

  .fc-form-field-label {
    display: none;
  }

  &.fc-form-field--has-label {
    .fc-form-field-label {
      display: block;
    }
  }
}

.fc-form-filed-input {
  position: relative;
}

.hints-level {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

textarea.fc-input {
  height: initial;
}

.fc-input {
  border: 1px solid $secondary-200;
  border-radius: 8px;
  padding: 10px 16px;
  color: $grey-900;
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  width: 100%;

  &:hover {
    border-color: $secondary-300;
  }

  &:focus {
    border-color: $primary-600;
    background: $primary-50;
  }

  &::placeholder {
    color: $grey-300;
  }

  &.ng-invalid.ng-touched {
    border-color: $error-500;
  }

  &.ng-invalid.ng-touched:focus {
    background: $error-50;
  }

  &.ng-invalid.ng-touched + .error-level {
    display: flex;
  }

  &.ng-invalid.ng-touched + .error-level + .hints-level {
    display: none;
  }
}

.fc-form-field--required {
  .fc-form-field-label::after {
    content: '*';
    color: $error-500;
  }
}

.fc-form-field--has-prefix {
  input.fc-input {
    padding-left: 40px;
  }
}

.fc-form-field--has-suffix {
  input.fc-input {
    padding-right: 40px;
  }
}

.fc-form-field-label {
  display: inline-block;
  color: $grey-900;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}

.fc-form-field-prefix,
.fc-form-field-suffix {
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:empty {
    display: none;
  }
}

.fc-form-field-prefix {
  left: 0;
  right: initial;
}

fc-icon-button.fc-suffix {
  right: 0;
}

// todo move  this to another file
.chips-list {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
