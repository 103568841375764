@use 'var' as *;
:root {
  --mat-datepicker-calendar-header-text-color: var(--grey-400);
}
// datepicker styles
.mat-calendar {
  .mat-calendar-controls {
    display: grid;
    grid-template-areas: 'previous period next';
    margin-top: unset;
  }

  .mat-calendar-previous-button {
    grid-area: previous;
    justify-self: center;
  }

  .mat-calendar-period-button {
    grid-area: period;
    justify-self: center;
  }

  .mat-calendar-next-button {
    grid-area: next;
    justify-self: center;
  }

  .mat-calendar-spacer {
    display: none;
  }
}

.mat-calendar-table-header {
  color: $grey-400;
}

// calendar styles
.mat-calendar-body-cell-content {
  --mat-datepicker-calendar-date-text-color: var(--grey-900);
}

.mat-calendar-body-selected {
  background-color: $primary-600;
  color: $white;
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: $grey-300;
}

.mat-datepicker-input {
  cursor: pointer;
}

// active days calendar
.mat-calendar-body-cell.active-days:after {
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background: $primary-600;
  border-radius: 100%;
  bottom: 6px;
  top: initial;
  left: 17px;
}

.mat-calendar.loading:after {
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.datepicker-container {
  width: 700px;
  background: white;
  box-shadow: $box-shadow-md;
  border-radius: 4px;
  height: auto;
}

@media (max-width: $sm-size) {
  .datepicker-container {
    height: initial;
    width: initial;
    margin: 0 14px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: initial;
  }
}
