$text-size-32: 32px;
$text-size-24: 24px;
$text-size-20: 20px;
$text-size-16: 16px;
$text-size-14: 14px;
$text-size-12: 12px;
$text-size-11: 11px;

$font-weight-500: 500;
$font-weight-400: 400;

$line-height-28: 28px;
$line-height-24: 24px;
$line-height-20: 20px;
$line-height-16: 16px;
$line-height-14: 14px;
$line-height-13: 13px;

$options-font-size: 16px;

$textSizeMap: (
  font-size-32: 32px,
  font-size-24: 24px,
  font-size-20: 20px,
  font-size-16: 16px,
  font-size-14: 14px,
  font-size-12: 12px,
  font-size-11: 11px,
);

@each $name, $size in $textSizeMap {
  .#{$name} {
    font-size: $size;
  }
}
